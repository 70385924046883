import Home from "../../v2/Containers/Home/Home";
import FitQuiz from "../Containers/FitQuiz/FitQuiz";
import SponsorAStudent from "../Containers/SponsorAStudent/SponsorAStudent";
import { routes } from "./routes";

export const routeComponents = [
  {
    route: routes.HOME,
    component: <Home />,
  },
  {
    route: routes.FIT_QUIZ,
    component: <FitQuiz />,
  },

  {
    route: routes.SPONSOR_A_STUDENT,
    component: <SponsorAStudent />,
  },
];
