import { useContext } from "react";
import Button from "../../Components/Button/Button";
import Loader from "../../Components/Loader/Loader";
import SchoolCard from "../../Components/SchoolCard/SchoolCard";
import { AppContext } from "../../Context/AppContext";
import { routes } from "../../Utilities/routes";
import { schoolsType } from "../../Utilities/types";
import classes from "./HomePageSchools.module.css";

type HomePageSchoolsTypes = {
  schools: schoolsType[];
  isLoading: boolean;
};

const HomePageSchools = ({ schools, isLoading }: HomePageSchoolsTypes) => {
  // Context
  const { coursesRef } = useContext(AppContext);

  return (
    <section className={classes.container} ref={coursesRef}>
      <div className={classes.header}>
        <p>WHAT WOULD YOU LIKE TO LEARN?</p>
        <h2>
          Courses available for{" "}
          <span>
            you
            <svg
              viewBox="0 0 106 49"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M104.303 18.6291C104.494 21.5302 103.368 24.5216 101.017 27.481C98.661 30.4461 95.112 33.3278 90.5879 35.9488C81.5421 41.1894 68.7682 45.2893 54.4164 46.9544C40.0647 48.6195 26.948 47.5234 17.3534 44.4454C12.5548 42.906 8.68235 40.8854 5.9742 38.5078C3.27125 36.1347 1.77059 33.4481 1.5792 30.547C1.3878 27.6459 2.51385 24.6545 4.865 21.6951C7.22067 18.73 10.7697 15.8483 15.2938 13.2273C24.3396 7.98672 37.1135 3.88681 51.4653 2.22173C65.817 0.556643 78.9338 1.65273 88.5283 4.73068C93.3269 6.27006 97.1994 8.29068 99.9075 10.6683C102.61 13.0414 104.111 15.728 104.303 18.6291Z"
                stroke="#664EFE"
                stroke-width="2"
              />
            </svg>
          </span>{" "}
          <svg
            width="36"
            height="41"
            viewBox="0 0 36 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.3478 0.407572C16.6872 1.13689 16.0082 3.87444 14.6075 11.2937C13.8083 15.6324 13.2863 17.3142 12.4667 18.308C11.5655 19.3824 11.0456 19.5249 7.34508 19.7067C5.44485 19.808 3.44473 19.9898 2.90443 20.1117C2.02363 20.2935 1.84421 20.4154 1.64441 20.9629C1.12449 22.341 1.50371 22.5042 8.70704 23.8409C11.7083 24.3884 12.6482 24.7335 13.109 25.4628C13.5494 26.1529 13.6901 26.8202 14.4098 31.7065C15.311 37.9501 15.7493 40.1794 16.1306 40.605C16.571 41.0905 17.3111 41.0905 17.7902 40.605C18.2694 40.1195 18.4712 39.1464 19.2113 33.3077C20.1308 26.2128 20.2715 25.7066 21.3909 25.3822C21.7905 25.281 23.9517 24.8967 26.2128 24.5723C32.1745 23.6798 33.1348 23.3162 32.9737 22.0187C32.8534 21.0042 32.2132 20.7625 26.8122 19.7274C21.5703 18.7129 20.7303 18.4712 20.5101 17.903C20.3307 17.4568 20.0697 13.5436 19.8107 7.54378C19.5702 2.33316 19.3907 0.996401 18.8708 0.407572C18.4508 -0.0593599 17.7698 -0.0593599 17.3498 0.407572H17.3478ZM18.2082 11.3949C18.3285 14.68 18.5283 17.7398 18.6282 18.1861C19.0686 19.8885 20.0676 20.4567 24.0088 21.2274C25.489 21.5104 27.1894 21.8554 27.7705 21.9567C28.3699 22.0786 28.8103 22.2211 28.7716 22.2604C28.7125 22.3017 26.851 22.6054 24.6306 22.9504C22.3899 23.2955 20.3103 23.7004 20.0085 23.8637C19.2093 24.2893 18.669 25.1405 18.3081 26.5186C18.0288 27.5929 17.0073 34.1816 17.0073 34.8903C17.0073 35.7415 16.7667 34.6072 16.2264 30.9565C15.8655 28.7272 15.4455 26.376 15.2661 25.7066C14.6463 23.4153 13.3659 22.686 8.56433 21.8554L5.70379 21.3699L8.30539 21.3492C11.3861 21.3286 12.167 21.1261 13.2272 20.1737C14.8481 18.6943 15.4476 17.0518 16.4874 11.2937C17.3478 6.59132 17.8881 4.21948 17.9472 4.90748C17.9676 5.19053 18.0879 8.10988 18.2082 11.3949Z"
              fill="#FDC500"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M31.6404 3.93583C30.5598 4.48334 25.9988 11.8819 26.48 12.3489C26.6594 12.5307 27.8399 11.2539 30.3008 8.25392C32.2011 5.92339 32.8005 4.98953 32.8005 4.38211C32.8005 4.03707 32.3805 3.61145 32.1399 3.71269C32.1195 3.71269 31.8993 3.81393 31.6404 3.93583Z"
              fill="#FDC500"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M33.5206 9.75223C32.6399 10.3204 29.92 13.1778 30.0994 13.3616C30.3196 13.6054 33.9794 11.6385 34.7399 10.8472C35.3006 10.2791 35.3801 10.0973 35.219 9.77289C34.9581 9.2667 34.2995 9.2667 33.5186 9.75223H33.5206Z"
              fill="#FDC500"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.30106 30.9387C1.68003 33.4717 0 34.8519 0 35.2775C0 35.5605 0.560696 36.1287 0.86041 36.1287C1.00109 36.1287 1.70042 35.7837 2.4018 35.358C4.563 34.1019 10.0047 28.4243 9.06279 28.4243C8.96288 28.4243 7.28285 29.5586 5.30106 30.9387Z"
              fill="#FDC500"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M26.6049 30.0675C26.6049 31.0612 29.445 34.1417 30.6051 34.4062C31.7856 34.6707 31.9651 33.9207 30.966 32.7843C30.3666 32.0757 27.1044 29.6418 26.7659 29.6418C26.6864 29.6418 26.6069 29.8237 26.6069 30.0675H26.6049Z"
              fill="#FDC500"
            />
          </svg>
        </h2>
      </div>
      <div className={classes.schoolsSection}>
        {isLoading ? (
          <Loader />
        ) : (
          schools?.map((school) => {
            return <SchoolCard data={school} />;
          })
        )}
      </div>

      <p>
        Still unsure how to move forward?
        <svg
          width="79"
          height="121"
          viewBox="0 0 79 121"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.37581 0.688948C-0.242375 1.60533 0.961731 3.71195 4.91482 8.74646C14.2701 20.4784 26.1332 31.4926 37.6962 39.0309L42.9482 42.5098L41.0597 47.2569C37.4783 56.5666 35.5695 68.314 35.6807 80.1929C35.7369 89.8943 38.3827 101.389 41.6937 106.375C41.9932 106.841 40.311 106.056 37.9642 104.693C33.7594 102.197 32.145 101.736 30.463 102.456C28.422 103.306 29.7676 104.749 37.3731 110.026C41.5795 112.959 45.8966 116.358 47.1394 117.776C48.4283 119.184 49.7992 120.525 50.2313 120.719C51.3891 121.331 53.4362 120.723 53.8845 119.699C54.1139 119.21 53.7841 116.032 53.0136 111.978C51.6079 104.037 51.5568 101.233 52.6706 94.1318C53.1485 91.3053 53.4256 88.6645 53.3317 88.2488C52.7786 85.801 48.7812 91.1217 47.7818 95.7219C47.3976 97.4591 47.0941 100.198 47.1267 101.846C47.1687 103.536 47.0453 104.923 46.8605 104.965C46.722 104.996 45.8755 103.828 45.0484 102.316C42.6747 98.0408 40.7816 88.803 40.7492 81.1404C40.6993 73.1852 41.4525 65.7765 42.9243 59.376C44.1366 53.9996 47.1457 45.1644 47.8385 45.0078C48.0232 44.9661 49.7143 45.3607 51.6215 45.8521C53.4825 46.3539 55.9856 46.9049 57.1365 47.0332C67.2687 48.2389 75.3097 43.4123 77.6918 34.8104C78.3962 32.127 78.4482 30.8531 77.9848 27.9427C77.2556 23.6414 76.1739 21.6475 73.4606 19.7363C69.6505 17.0535 63.957 17.6603 58.6647 21.3319C55.1861 23.7684 52.0348 27.2474 48.23 32.9668L44.8584 38.049L41.1362 35.5406C32.3521 29.5588 21.3042 19.574 11.289 8.57373C4.2552 0.88578 3.02887 -0.244926 1.6433 0.0681465C1.08907 0.193376 0.473784 0.478034 0.37697 0.694084L0.37581 0.688948ZM70.4258 23.0865C73.9332 24.4299 75.5932 28.7689 74.326 33.4727C72.1843 41.8261 63.5367 45.0422 52.866 41.477L49.9247 40.4911L50.7707 38.6495C52.0293 35.8408 57.3245 28.9594 59.8794 26.7316C63.8323 23.3627 67.6076 22.0242 70.4207 23.0876L70.4258 23.0865Z"
            fill="black"
          />
        </svg>
      </p>

      <div className={classes.quizSection}>
        <p>
          We’ve curated a career quiz to help you determine your strengths. If
          you prefer one-on-one support, speak to an advisor for free
        </p>

        <div className={classes.buttonSection}>
          <Button
            type="white"
            onClick={() => {
              window.open(
                `${window.location.origin}${routes.FIT_QUIZ}?step=intro`
              );
            }}
          >
            Take our quiz
          </Button>
          <Button type="transparent-white">Speak to advisor</Button>
        </div>
      </div>

      <svg
        width="265"
        height="237"
        viewBox="0 0 265 237"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="32.9694"
          cy="54.9084"
          r="80.3802"
          transform="rotate(-26.6517 32.9694 54.9084)"
          fill="#2BA792"
        />
        <circle
          cx="119.868"
          cy="40.4075"
          r="80.3802"
          transform="rotate(-26.6517 119.868 40.4075)"
          fill="#FFD029"
        />
      </svg>

      <svg
        width="140"
        height="226"
        viewBox="0 0 140 226"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="10.2291"
          y="139.064"
          width="195.798"
          height="54.0133"
          transform="rotate(-34.6796 10.2291 139.064)"
          fill="#2BA792"
        />
        <rect
          x="39.7493"
          y="182.239"
          width="195.798"
          height="20.255"
          transform="rotate(-35.8642 39.7493 182.239)"
          fill="#FFD029"
        />
      </svg>

      <svg
        width="220"
        height="349"
        viewBox="0 0 220 349"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="201.17"
          height="127.191"
          transform="matrix(-0.681331 0.731975 -0.731629 -0.681703 122.452 183.312)"
          fill="#FFD029"
        />
        <rect
          width="167.107"
          height="12.3054"
          transform="matrix(-0.690768 0.723077 -0.722731 -0.691129 47.6935 113.478)"
          fill="#2BA792"
        />
        <rect
          width="142.443"
          height="12.3054"
          transform="matrix(-0.690768 0.723077 -0.722731 -0.691129 146.092 10.5166)"
          fill="#FFD029"
        />
      </svg>

      <svg
        width="161"
        height="226"
        viewBox="0 0 161 226"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="-48.7742"
          y="139.064"
          width="195.798"
          height="54.0133"
          transform="rotate(-34.6796 -48.7742 139.064)"
          fill="#2BA792"
        />
        <rect
          x="-19.254"
          y="182.239"
          width="195.798"
          height="20.255"
          transform="rotate(-35.8642 -19.254 182.239)"
          fill="#FFD029"
        />
      </svg>

      <svg
        width="220"
        height="254"
        viewBox="0 0 220 254"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="144.969"
          cy="195.908"
          r="80.3802"
          transform="rotate(-26.6517 144.969 195.908)"
          fill="#2BA792"
        />
        <circle
          cx="231.868"
          cy="181.407"
          r="80.3802"
          transform="rotate(-26.6517 231.868 181.407)"
          fill="#FFD029"
        />
      </svg>
    </section>
  );
};

export default HomePageSchools;
