import React, {
  createContext,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useRef,
  useState,
} from "react";

type AppContextValues = {
  coursesRef: any;
  scrollTOView: () => void;
  notifications: notificationsType;
  setNotifications: Dispatch<SetStateAction<notificationsType>>;
  overviewRef: MutableRefObject<HTMLDivElement | null>;
  schoolCourseRef: MutableRefObject<HTMLDivElement | null>;
  tutorsRef: MutableRefObject<HTMLDivElement | null>;
  benefits: MutableRefObject<HTMLDivElement | null>;

  courseOverview: MutableRefObject<HTMLDivElement | null>;
  courseCurricullum: MutableRefObject<HTMLDivElement | null>;
  courseRequirements: MutableRefObject<HTMLDivElement | null>;
  courseStartDate: MutableRefObject<HTMLDivElement | null>;
  coursePayment: MutableRefObject<HTMLDivElement | null>;
  courseFaqs: MutableRefObject<HTMLDivElement | null>;
};

type AppContextProviderProps = {
  children: React.ReactNode;
};

export type notificationsType =
  | {
      title: string;
      severity: "success" | "error" | "mid";
      description?: string;
      id: string | number;
    }[]
  | null
  | undefined;

export const AppContext = createContext<AppContextValues>(
  {} as AppContextValues
);

const AppContextProvider = ({ children }: AppContextProviderProps) => {
  // Ref
  const coursesRef = useRef<HTMLDivElement>(null);

  // States
  const [notifications, setNotifications] = useState<notificationsType>(null);

  // refs
  const overviewRef = useRef<HTMLDivElement | null>(null);
  const schoolCourseRef = useRef<HTMLDivElement | null>(null);
  const tutorsRef = useRef<HTMLDivElement | null>(null);
  const benefits = useRef<HTMLDivElement | null>(null);

  const courseOverview = useRef<HTMLDivElement | null>(null);
  const courseCurricullum = useRef<HTMLDivElement | null>(null);
  const courseRequirements = useRef<HTMLDivElement | null>(null);
  const courseStartDate = useRef<HTMLDivElement | null>(null);
  const coursePayment = useRef<HTMLDivElement | null>(null);
  const courseFaqs = useRef<HTMLDivElement | null>(null);

  //   Utils
  const scrollTOView = () => {
    if (coursesRef?.current) {
      coursesRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  return (
    <AppContext.Provider
      value={{
        coursesRef,
        scrollTOView,
        notifications,
        setNotifications,
        overviewRef,
        schoolCourseRef,
        tutorsRef,
        benefits,
        courseOverview,
        courseCurricullum,
        courseRequirements,
        courseStartDate,
        coursePayment,
        courseFaqs,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
