import { createContext, LegacyRef, useRef } from "react";

type AppContextTypes = {
  coursesRef: LegacyRef<HTMLDivElement> | null;
  scrollTOView: () => void;
};

type AppContextProviderTypes = {
  children: React.ReactNode;
};

export const AppContext = createContext({} as AppContextTypes);

const AppContextProvider = ({ children }: AppContextProviderTypes) => {
  // Ref
  const coursesRef = useRef<HTMLDivElement>(null);

  const scrollTOView = () => {
    if (coursesRef?.current) {
      coursesRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  return (
    <AppContext.Provider value={{ coursesRef, scrollTOView }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
