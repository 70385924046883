import classes from "./SchoolCard.module.css";
import iseLogo from "../../Assets/Images/iseLogo.svg";
import Button from "../../../Components/Button/Button";
import { schoolsType } from "../../Utilities/types";

type SchoolCardType = {
  data: schoolsType;
};

const SchoolCard = ({ data }: SchoolCardType) => {
  return (
    <div className={classes.container}>
      <h4>{data?.name}</h4>
      <p>
        <span>Courses in this school</span>: Frontend Development, Backend
        Development, Cloud DevOps, Cloud DevSecOps
      </p>
      <img src={data?.image || iseLogo} alt="School" />
      <p>{data?.description}</p>
      <div className={classes.infoSection}>
        <span>3- months</span>
        <span>Beginners friendly</span>
      </div>
      <Button
        onClick={() => {
          window.open("https://selar.co/m/iseschool");
        }}
      >
        Explore course
      </Button>
    </div>
  );
};

export default SchoolCard;
