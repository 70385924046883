import React from "react";
import Layout from "../../Components/Layout/Layout";
import SpomsorAStudentOptions from "../SpomsorAStudentOptions/SpomsorAStudentOptions";
import SponsorAStudentFAQs from "../SponsorAStudentFAQs/SponsorAStudentFAQs";
import SponsorAStudentHeroSection from "../SponsorAStudentHeroSection/SponsorAStudentHeroSection";

const SponsorAStudent = () => {
  return (
    <Layout>
      <SponsorAStudentHeroSection />
      <SpomsorAStudentOptions />
      <SponsorAStudentFAQs />
    </Layout>
  );
};

export default SponsorAStudent;
