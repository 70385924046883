import Button from "../../Components/Button/Button";
import classes from "./FitQuixCompleted.module.css";

const FitQuixCompleted = () => {
  return (
    <div className={classes.container}>
      <p>
        In the meantime, browse our school page and explore the courses we offer
      </p>

      <div className={classes.buttonSection}>
        <Button>
          <span>Sign up</span>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17 8L21 12M21 12L17 16M21 12L3 12"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Button>
        <Button type="secondary">
          <span>Explore our courses</span>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17 8L21 12M21 12L17 16M21 12L3 12"
              stroke="#664EFE"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Button>
      </div>
    </div>
  );
};

export default FitQuixCompleted;
