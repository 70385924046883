import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import AppContextProvider from "./v2/Context/AppContext";
import SchoolsContextProvider from "./Context/SchoolsContext";
import Toast from "./Components/Toast/Toast";
import FormsContextProvider from "./Context/FormsContext";
import UseSWRConfigProvider from "./v2/Configs/useSWRConfig";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Router>
    <UseSWRConfigProvider>
      <AppContextProvider>
        <FormsContextProvider>
          <SchoolsContextProvider>
            <React.StrictMode>
              <Toast>
                <App />
              </Toast>
            </React.StrictMode>
          </SchoolsContextProvider>
        </FormsContextProvider>
      </AppContextProvider>
    </UseSWRConfigProvider>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
