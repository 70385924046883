import { useMemo } from "react";
import Loader from "../../Components/Loader/Loader";
import Layout from "../../Components/Layout/Layout";
import { useSchool } from "../../Hooks/useSchools";
import { schoolsType } from "../../Utilities/types";
import HomePageAffordable from "../HomePageAffordable/HomePageAffordable";
import HomePageBuildWithEveryone from "../HomePageBuildWithEveryone/HomePageBuildWithEveryone";
import HomePageCollaborate from "../HomePageCollaborate/HomePageCollaborate";
import HomePageFaqs from "../HomePageFaqs/HomePageFaqs";
import HomePageHeroSection from "../HomePageHeroSection/HomePageHeroSection";
import HomePageLearn from "../HomePageLearn/HomePageLearn";
import HomePageSchools from "../HomePageSchools/HomePageSchools";
import HomePageWhoWeAre from "../HomePageWhoWeAre/HomePageWhoWeAre";

const Home = () => {
  // Requests
  const { isLoading, data } = useSchool();

  const schools: schoolsType[] = useMemo(() => data?.data, [data]);

  return (
    <Layout>
      <HomePageHeroSection />
      <HomePageSchools schools={schools} isLoading={isLoading} />
      <HomePageLearn />
      <HomePageBuildWithEveryone />
      <HomePageWhoWeAre />
      <HomePageFaqs />
      <HomePageAffordable />
    </Layout>
  );
};

export default Home;
