import { useSearchParams } from "react-router-dom";
import Button from "../../Components/Button/Button";
import classes from "./FitQuizIntroduction.module.css";

const instructions = [
  "Please be honest in your answers to ensure the most accurate recommendations",
  "There are no right or wrong answers—this is about finding the best fit for you.",
  "All responses are confidential and will be used solely for providing personalized advice",
];

const FitQuizIntroduction = () => {
  // Router
  const [, setSearchParams] = useSearchParams();
  return (
    <div className={classes.container}>
      <Button
        onClick={() => {
          setSearchParams((prevState) => {
            return { ...prevState, step: "1" };
          });
        }}
      >
        <span>Continue to quiz</span>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17 8L21 12M21 12L17 16M21 12L3 12"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Button>

      <ul className={classes.instructions}>
        {instructions.map((data) => {
          return (
            <li key={data}>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="16" cy="16" r="15.5" stroke="#D8D8D8" />
                <path
                  d="M9 17L13 21L23 11"
                  stroke="#737373"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>{data}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default FitQuizIntroduction;
