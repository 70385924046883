import { backend_url } from "../Utilities/global";
import useGetHook from "./useGetHook";

export const useSchool = () => {
  const url = `${backend_url}/api/ise/v1/school`;

  return useGetHook(url, {
    revalidateOnFocus: false,
  });
};

export const useSchoolCourses = (id: string) => {
  const url = `${backend_url}/api/ise/v1/school/landing/${id}/courses`;

  return useGetHook(url, {
    revalidateOnFocus: false,
  });
};
