import axios from "axios";
import {
  createContext,
  SetStateAction,
  useState,
  Dispatch,
  useContext,
  useEffect,
} from "react";
import { setNotiticationFunction } from "../Utilities/setNotificationsFunction";
import { AppContext } from "./AppContext";
import { requestType } from "./SchoolsContext";
import { backend_url } from "../Utilities/global";

type FormsContextValues = {
  sponsorshipFormData: sponsorshipFormData;
  setSponsorshipFormData: Dispatch<SetStateAction<sponsorshipFormData>>;
  postSponsorshipFormData: () => void;
  postSponsorshipFormRequest: requestType;
  laptopDonationFormData: laptopDonationFormData;
  setLaptopDonationFormData: Dispatch<SetStateAction<laptopDonationFormData>>;
  postDonationFormData: () => void;
  postDonationFormRequest: requestType;
  setPostDOnationFormRequest: Dispatch<SetStateAction<requestType>>;
  partnershipFormData: partnershipFormData;
  setPartnershipFormData: Dispatch<SetStateAction<partnershipFormData>>;
  postPartnershipFormData: () => void;
  partnershioFormRequest: requestType;
  tutorFormRequest: requestType;
  setTutorFormRequest: Dispatch<SetStateAction<requestType>>;
  tutorFormData: tutorApplication;
  setTutorFormData: Dispatch<SetStateAction<tutorApplication>>;
  postTutorFormData: () => void;
  setPosySponsorshipFormRequest: Dispatch<SetStateAction<requestType>>;
  setPostPartnershipFormRequest: Dispatch<SetStateAction<requestType>>;
};

type FormsContextProviderProps = {
  children: React.ReactNode;
};

type sponsorshipFormData = {
  fullname: string;
  email: string;
  phone: string;
  organization: string;
  number_of_sponsors: string;
  sponsorship_criteria: string;
  contact_medium: string;
  sponsorship_type: string;
};

type laptopDonationFormData = {
  fullname: string;
  email: string;
  phone: string;
  laptop_age: string;
  laptop_accessories: string;
  laptop_current_condition: string;
  laptop_brand: string;
  laptop_model: string;
  contact_medium: string;
};

type partnershipFormData = {
  fullname: string;
  companyName: string;
  email: string;
  phone: string;
  website: string;
  partnershipType: string;
  partnershipObjective: string;
  attachments: any;
  contact_medium: string;
};

type tutorApplication = {
  fullname: string;
  email: string;
  phone: string;
  linkedIn: string;
  teachingExperience: string;
  expertise: string;
  yearsOfExperience: string;
  resume: string;
  educationLevel: string;
  contact_medium: string;
  experienceInField: string;
};

export const FormsContext = createContext({} as FormsContextValues);

const FormsContextProvider = ({ children }: FormsContextProviderProps) => {
  // COntext
  const { setNotifications } = useContext(AppContext);

  // States
  const [sponsorshipFormData, setSponsorshipFormData] =
    useState<sponsorshipFormData>({
      fullname: "",
      email: "",
      phone: "",
      organization: "",
      number_of_sponsors: "",
      sponsorship_criteria: "",
      contact_medium: "",
      sponsorship_type: "",
    });

  const [laptopDonationFormData, setLaptopDonationFormData] =
    useState<laptopDonationFormData>({
      fullname: "",
      email: "",
      phone: "",
      laptop_age: "",
      laptop_accessories: "",
      laptop_current_condition: "",
      laptop_brand: "",
      laptop_model: "",
      contact_medium: "",
    });

  const [partnershipFormData, setPartnershipFormData] =
    useState<partnershipFormData>({
      fullname: "",
      companyName: "",
      email: "",
      phone: "",
      website: "",
      partnershipType: "",
      partnershipObjective: "",
      attachments: "",
      contact_medium: "",
    });

  const [tutorFormData, setTutorFormData] = useState<tutorApplication>({
    fullname: "",
    email: "",
    phone: "",
    linkedIn: "",
    teachingExperience: "",
    expertise: "",
    yearsOfExperience: "",
    experienceInField: "",
    resume: "",
    educationLevel: "",
    contact_medium: "",
  });

  const [postSponsorshipFormRequest, setPosySponsorshipFormRequest] =
    useState<requestType>({
      data: null,
      error: null,
      isLoading: false,
    });

  const [postDonationFormRequest, setPostDOnationFormRequest] =
    useState<requestType>({
      data: null,
      error: null,
      isLoading: false,
    });

  const [partnershioFormRequest, setPostPartnershipFormRequest] =
    useState<requestType>({
      data: null,
      error: null,
      isLoading: false,
    });

  const [tutorFormRequest, setTutorFormRequest] = useState<requestType>({
    data: null,
    error: null,
    isLoading: false,
  });

  // Formdata

  const partnershipApplicationFormData = new FormData();

  // Effects
  useEffect(() => {
    partnershipApplicationFormData.append(
      "attachment",
      partnershipFormData.attachments
    );
    partnershipApplicationFormData.append("email", partnershipFormData.email);
    partnershipApplicationFormData.append(
      "fullname",
      partnershipFormData.fullname
    );
    partnershipApplicationFormData.append("phone", partnershipFormData.phone);
    partnershipApplicationFormData.append(
      "company_name",
      partnershipFormData.companyName
    );
    partnershipApplicationFormData.append(
      "website_url",
      partnershipFormData.website
    );
    partnershipApplicationFormData.append(
      "partnership_type",
      partnershipFormData.partnershipType
    );
    partnershipApplicationFormData.append(
      "partnership_objective",
      partnershipFormData.partnershipObjective
    );
    partnershipApplicationFormData.append(
      "contact_medium",
      partnershipFormData.contact_medium.toUpperCase()
    );

    // eslint-disable-next-line
  }, [partnershipFormData]);

  // Requests
  const postSponsorshipFormData = () => {
    setPosySponsorshipFormRequest({
      data: null,
      error: null,
      isLoading: true,
    });
    axios
      .post(`${backend_url}/api/ise/v1/sponsorship-form`, {
        fullname: sponsorshipFormData.fullname,
        email: sponsorshipFormData.email,
        phone: sponsorshipFormData.phone,
        organization: sponsorshipFormData.organization,
        number_of_sponsors: Number(sponsorshipFormData.number_of_sponsors),
        sponsorship_criteria: sponsorshipFormData.sponsorship_criteria,
        contact_medium: sponsorshipFormData.contact_medium.toUpperCase(),
        sponsorship_type: sponsorshipFormData.sponsorship_type,
      })
      .then((res) => {
        setPosySponsorshipFormRequest({
          data: res?.data,
          error: null,
          isLoading: false,
        });
      })
      .catch((err) => {
        setNotiticationFunction(
          setNotifications,
          err.response ? err.response.data.error.responseMessage : err.message
        );
        setPosySponsorshipFormRequest({
          data: null,
          error: null,
          isLoading: false,
        });
      });
  };

  const postDonationFormData = () => {
    setPostDOnationFormRequest({
      data: null,
      error: null,
      isLoading: true,
    });
    axios
      .post(`${backend_url}/api/ise/v1/donations-form`, {
        fullname: laptopDonationFormData.fullname,
        email: laptopDonationFormData.email,
        phone: laptopDonationFormData.phone,
        laptop_age: laptopDonationFormData.laptop_age,
        accessories: laptopDonationFormData.laptop_accessories,
        condition: laptopDonationFormData.laptop_current_condition,
        brand: laptopDonationFormData.laptop_brand,
        model: laptopDonationFormData.laptop_model,
        contact_medium: laptopDonationFormData.contact_medium.toUpperCase(),
      })
      .then((res) => {
        setPostDOnationFormRequest({
          data: "success",
          error: null,
          isLoading: false,
        });
      })
      .catch((err) => {
        setNotiticationFunction(
          setNotifications,
          err.response ? err.response.data.error.responseMessage : err.message
        );
        setPostDOnationFormRequest({
          data: null,
          error: err.response
            ? err.response.data.error.responseMessage
            : err.message,
          isLoading: false,
        });
      });
  };

  const postPartnershipFormData = () => {
    setPostPartnershipFormRequest({
      data: null,
      error: null,
      isLoading: true,
    });
    axios
      .post(
        `${backend_url}/api/ise/v1/partnership-form`,
        partnershipApplicationFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        setPostPartnershipFormRequest({
          data: res?.data,
          error: null,
          isLoading: false,
        });
      })
      .catch((err) => {
        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message
        );
        setPostPartnershipFormRequest({
          data: null,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
          isLoading: false,
        });
      });
  };

  const tutorApplicationFormData = new FormData();

  // Effects
  useEffect(() => {
    let firstname = "";
    let lastname = "";

    if (tutorFormData.fullname) {
      firstname = tutorFormData.fullname.split(" ")[0];
      lastname = tutorFormData.fullname.split(" ")[1];
    }

    tutorApplicationFormData.append("resume", tutorFormData.resume);
    tutorApplicationFormData.append("email", tutorFormData.email);
    tutorApplicationFormData.append("firstname", firstname);
    tutorApplicationFormData.append("lastname", lastname);
    tutorApplicationFormData.append("phone", tutorFormData.phone);
    tutorApplicationFormData.append("linkedin_profile", tutorFormData.linkedIn);
    tutorApplicationFormData.append(
      "teaching_experience",
      tutorFormData.yearsOfExperience
    );
    tutorApplicationFormData.append(
      "selected_field_experience",
      tutorFormData.experienceInField
    );
    tutorApplicationFormData.append(
      "area_of_expertise",
      tutorFormData.expertise
    );
    tutorApplicationFormData.append(
      "education_level",
      tutorFormData.educationLevel
    );
    tutorApplicationFormData.append(
      "contact_medium",
      tutorFormData.contact_medium.toUpperCase()
    );

    // eslint-disable-next-line
  }, [tutorFormData]);

  const postTutorFormData = () => {
    setTutorFormRequest({
      data: null,
      error: null,
      isLoading: true,
    });
    axios
      .post(`${backend_url}/api/ise/v1/tutors-form`, tutorApplicationFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setTutorFormRequest({
          data: res.data,
          error: null,
          isLoading: false,
        });
      })
      .catch((err) => {
        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message
        );
        setTutorFormRequest({
          data: null,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
          isLoading: false,
        });
      });
  };

  return (
    <FormsContext.Provider
      value={{
        sponsorshipFormData,
        setSponsorshipFormData,
        postSponsorshipFormData,
        postSponsorshipFormRequest,
        laptopDonationFormData,
        setLaptopDonationFormData,
        postDonationFormData,
        postDonationFormRequest,
        setPostDOnationFormRequest,
        partnershipFormData,
        setPartnershipFormData,
        postPartnershipFormData,
        partnershioFormRequest,
        tutorFormData,
        tutorFormRequest,
        setTutorFormData,
        postTutorFormData,
        setTutorFormRequest,
        setPosySponsorshipFormRequest,
        setPostPartnershipFormRequest,
      }}
    >
      {children}
    </FormsContext.Provider>
  );
};

export default FormsContextProvider;
