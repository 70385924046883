import { useState } from "react";
import Button from "../../Components/Button/Button";
import FAQDropdown from "../../Components/FAQDropdown/FAQDropdown";
import { activeToggler } from "../../HelperFunctions/activeTogglers";
import { SELAR_LINK } from "../../Utilities/constants";
import classes from "./HomePageFaqs.module.css";

const faqs = [
  {
    question: "How are you different from other online schools",
    answer:
      "At iṣẹ́ School we focus on affordable yet quality education. We believe career prospects in tech are more than design and coding, so we offer non-conventional yet highly demanded courses that help you build knowledge and your tech career. We also pride ourselves on being a super supportive platform, so we have 1-on-1 tutoring and student calls to help our students grow.",
  },
  {
    question: "What’s with the brand name?",
    answer:
      "iṣẹ́ is a Yoruba word meaning work. Our founder wanted a name that encompassed the purpose of the platform. We help you learn the right skills, to help you work effectively, and succeed in your tech dreams.",
  },
  {
    question: "Can I teach at iṣẹ School?",
    answer:
      "Yes, you can. Tutors with expert track records in their respective fields are welcome to teach at iṣẹ́ School. Go to the “Become a tutor” page to learn more about the requirements.",
  },
  {
    question: "Who is iṣẹ School for?",
    answer:
      "iṣẹ School is for everyone looking to learn a new skill, switch careers or try a new path in the tech space. Our courses are beginner friendly.",
  },
  {
    question: "What is the class schedule like?",
    answer:
      "We have 2 live classes every week, where students participate in a live virtual class. Asides that, the courses are self paced and our working learners, can learn at their convenient time.",
  },
  {
    question: "What are your course payment plans?",
    answer:
      "We have 2 payment plans. The instalment and full payment plans. With the instalment plan, you spread your course payment across 3months after paying an initial sum of 15,000 Naira. The full payment is 40,000 Naira, you get a 10% discount when you pay for your course at once.",
  },
];

const HomePageFaqs = () => {
  // States
  const [faqState, setFaqState] = useState(
    faqs.map((data) => {
      return { ...data, isActive: false };
    })
  );

  console.log(faqState, "check");

  return (
    <section className={classes.container}>
      <div className={classes.header}>
        <p>FREQUENTLY ASKED QUESTIONS</p>
        <h2>We’ve got answers</h2>
      </div>

      <div className={classes.faqContainer}>
        {faqState.map((data, i) => {
          return (
            <FAQDropdown
              question={data?.question}
              answer={data?.answer}
              key={i}
              onClick={() => {
                activeToggler(i, faqState, setFaqState);
              }}
              isActive={data?.isActive}
            />
          );
        })}
      </div>

      <p>
        It’s time to go big
        <svg
          width="79"
          height="121"
          viewBox="0 0 79 121"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.37581 0.688948C-0.242375 1.60533 0.961731 3.71195 4.91482 8.74646C14.2701 20.4784 26.1332 31.4926 37.6962 39.0309L42.9482 42.5098L41.0597 47.2569C37.4783 56.5666 35.5695 68.314 35.6807 80.1929C35.7369 89.8943 38.3827 101.389 41.6937 106.375C41.9932 106.841 40.311 106.056 37.9642 104.693C33.7594 102.197 32.145 101.736 30.463 102.456C28.422 103.306 29.7676 104.749 37.3731 110.026C41.5795 112.959 45.8966 116.358 47.1394 117.776C48.4283 119.184 49.7992 120.525 50.2313 120.719C51.3891 121.331 53.4362 120.723 53.8845 119.699C54.1139 119.21 53.7841 116.032 53.0136 111.978C51.6079 104.037 51.5568 101.233 52.6706 94.1318C53.1485 91.3053 53.4256 88.6645 53.3317 88.2488C52.7786 85.801 48.7812 91.1217 47.7818 95.7219C47.3976 97.4591 47.0941 100.198 47.1267 101.846C47.1687 103.536 47.0453 104.923 46.8605 104.965C46.722 104.996 45.8755 103.828 45.0484 102.316C42.6747 98.0408 40.7816 88.803 40.7492 81.1404C40.6993 73.1852 41.4525 65.7765 42.9243 59.376C44.1366 53.9996 47.1457 45.1644 47.8385 45.0078C48.0232 44.9661 49.7143 45.3607 51.6215 45.8521C53.4825 46.3539 55.9856 46.9049 57.1365 47.0332C67.2687 48.2389 75.3097 43.4123 77.6918 34.8104C78.3962 32.127 78.4482 30.8531 77.9848 27.9427C77.2556 23.6414 76.1739 21.6475 73.4606 19.7363C69.6505 17.0535 63.957 17.6603 58.6647 21.3319C55.1861 23.7684 52.0348 27.2474 48.23 32.9668L44.8584 38.049L41.1362 35.5406C32.3521 29.5588 21.3042 19.574 11.289 8.57373C4.2552 0.88578 3.02887 -0.244926 1.6433 0.0681465C1.08907 0.193376 0.473784 0.478034 0.37697 0.694084L0.37581 0.688948ZM70.4258 23.0865C73.9332 24.4299 75.5932 28.7689 74.326 33.4727C72.1843 41.8261 63.5367 45.0422 52.866 41.477L49.9247 40.4911L50.7707 38.6495C52.0293 35.8408 57.3245 28.9594 59.8794 26.7316C63.8323 23.3627 67.6076 22.0242 70.4207 23.0876L70.4258 23.0865Z"
            fill="black"
          />
        </svg>
      </p>

      <div className={classes.quizSection}>
        <p>
          Our courses, expert tutors and vast resources are created to give you
          a solid foundation for your career path.
        </p>

        <div className={classes.buttonSection}>
          <Button
            type="white"
            onClick={() => {
              window.open(SELAR_LINK);
            }}
          >
            Start learning
          </Button>
        </div>
      </div>

      <svg
        width="204"
        height="304"
        viewBox="0 0 204 304"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="4.49069"
          cy="132.379"
          r="80.3802"
          transform="rotate(8.91474 4.49069 132.379)"
          fill="#2BA792"
        />
        <circle
          cx="83.6119"
          cy="171.128"
          r="80.3802"
          transform="rotate(8.91474 83.6119 171.128)"
          fill="#FFD029"
        />
      </svg>

      <svg
        width="138"
        height="226"
        viewBox="0 0 138 226"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="10.2258"
          y="139.064"
          width="195.798"
          height="54.0133"
          transform="rotate(-34.6796 10.2258 139.064)"
          fill="#2BA792"
        />
        <rect
          x="39.746"
          y="182.239"
          width="195.798"
          height="20.255"
          transform="rotate(-35.8642 39.746 182.239)"
          fill="#FFD029"
        />
      </svg>

      <svg
        width="241"
        height="349"
        viewBox="0 0 241 349"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="201.17"
          height="127.191"
          transform="matrix(-0.681331 0.731975 -0.731629 -0.681703 142.975 183.025)"
          fill="#FFD029"
        />
        <rect
          width="167.107"
          height="12.3054"
          transform="matrix(-0.690768 0.723077 -0.722731 -0.691129 68.2158 113.191)"
          fill="#2BA792"
        />
        <rect
          width="142.443"
          height="12.3054"
          transform="matrix(-0.690768 0.723077 -0.722731 -0.691129 166.614 10.2295)"
          fill="#FFD029"
        />
      </svg>

      <svg
        width="199"
        height="304"
        viewBox="0 0 199 304"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="119.491"
          cy="132.379"
          r="80.3802"
          transform="rotate(8.91474 119.491 132.379)"
          fill="#2BA792"
        />
        <circle
          cx="198.612"
          cy="171.128"
          r="80.3802"
          transform="rotate(8.91474 198.612 171.128)"
          fill="#FFD029"
        />
      </svg>
    </section>
  );
};

export default HomePageFaqs;
