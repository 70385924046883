import classes from "./SponsorAStudentHeroSection.module.css";
import SectionsHeader from "../../../Components/SectionsHeader/SectionsHeader";

const SponsorAStudentHeroSection = () => {
  return (
    <section className={classes.container}>
      <SectionsHeader
        paragraph="WE NEED YOU"
        header="Sponsor a student in tech"
        description="Make a difference in a student's tech journey by offering scholarships, laptops, and valuable opportunities. Empower the next generation of tech leaders with your support. "
        style={{ textAlign: "center" }}
      />
    </section>
  );
};

export default SponsorAStudentHeroSection;
