import FAQDropdown from "../../Components/FAQDropdown/FAQDropdown";
import SectionsHeader from "../../../Components/SectionsHeader/SectionsHeader";
import classes from "./SponsorAStudentFAQs.module.css";

const SponsorAStudentFAQs = () => {
  // Utils
  const faqs = [
    {
      question:
        "How can I Sponsor a student’s tech education through Ise Edtech?",
      answer:
        "Click on the button 'Sponsor a student' to access the sponsorship form. The form will require basic information about you and your preferred sponsorship amount. ",
    },

    {
      question: "Will I receive updates on the student's progress?",
      answer:
        "Yes, we will provide periodic updates on the student's progress throughout their course of study.",
    },
    {
      question: "How much does it cost to sponsor a student?",
      answer:
        "The cost of sponsoring a student depends on the course they are enrolled in. The price of the courses starts at 60,000 Naira. Please visit our course page to see the cost for each course.",
    },
    {
      question: "Can I sponsor more than one student?",
      answer:
        "Yes, you can sponsor more than one student. Simply indicate the number of students or the range you would like to sponsor on the donation form.",
    },
    {
      question: "Can I choose which student I want to sponsor?",
      answer:
        "No, you can't choose a student. We have a system that selects who gets sponsored to prevent bias.",
    },
    {
      question: "Can I donate a laptop instead of sponsoring a student?",
      answer:
        'Yes, you can donate a laptop. Click on the button "Donate now" to fill the donation form. The form requires basic information about the laptop you’d be donating.',
    },
    {
      question: "Are donations tax-deductible?",
      answer:
        "Yes, donations to iṣẹ EdTech are tax-deductible to the extent law allows. We will provide a receipt for your donation upon request.",
    },
    {
      question: "How do I know my donation is being used appropriately?",
      answer:
        "You can trust our commitment to transparency. We'll provide a personalized downloadable report showing exactly how we utilized your donation if you request it.",
    },
  ];
  return (
    <section className={classes.container}>
      <SectionsHeader
        paragraph="YOU HAVE QUESTIONS?"
        header="We’ve got answers"
        style={{ textAlign: "center" }}
      />

      <div className={classes.faqSection}>
        {faqs.map((data, i) => {
          return (
            <div key={i} className={classes.faq}>
              <FAQDropdown question={data.question} answer={data.answer} />
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default SponsorAStudentFAQs;
